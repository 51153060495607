const LocationReviewsData = [
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Bryan Ellis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Todd A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Gregory Pittman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Desiree W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Cheryl Rodriguez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ryan F.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Jeremy Thomas",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Pamela M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Jessica Acosta",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Susan D.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Ellen Jordan",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Randall B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Sarah Anderson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sean J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Kyle Bennett",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sarah S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Bridget Robinson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Allen L.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "John Robertson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ross R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Ryan Duncan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Susan L.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Crystal Hawkins",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Pamela F.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Meredith Bowman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "John W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Patricia Mason",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael C.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Rebecca Mills",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Zachary H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Matthew Hendricks",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Douglas N.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Dana Mcdonald",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mark J.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Erin Winters",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Stephen P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Tiffany Hammond",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jennifer H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Lynn Nguyen",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Cynthia D.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Amber Maxwell",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jimmy C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Breanna Johnson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christine W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Richard Mckenzie",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tyler S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Derrick Miller",
        "service_type": "Garage Door Opener",
        "tech_name": "Jasmine F.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Tony Boyle",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tracie C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Jessica Young",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jacqueline M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Jennifer Stephens",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Matthew C.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Vickie Rodgers",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amanda S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Taylor Adkins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Laura P.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Kevin Hansen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Derek C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Rebecca Powers",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Raymond W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Julia Woods",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sherry L.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Edward Oneal",
        "service_type": "Garage Door Services",
        "tech_name": "Michelle A.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Sandra Martin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Carla J.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Joseph Joyce",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alison S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Lauren Reyes",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brandon H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Anne Olson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Willie L.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Lisa Rivera",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christine H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Jennifer Rodriguez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jessica W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Ruth Gross",
        "service_type": "Garage Door Services",
        "tech_name": "Matthew C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "William Burton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Deborah B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Cox",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gerald M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Linda Robertson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tina H.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-17",
        "review_rate": 5.0,
        "customer_name": "Julia Welch",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Mark W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Adam Santana",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher W.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.8,
        "customer_name": "Andrea Richards",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Andrea S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Gary Love",
        "service_type": "Garage Door Opener",
        "tech_name": "Joshua T.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Mariah Taylor",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jessica S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Tommy Wright",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mary J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Kimberly Shepherd",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Hunter P.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "George Rodriguez",
        "service_type": "Garage Door Repair",
        "tech_name": "Brian M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Brian Wagner",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tiffany P.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Aaron Anderson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Steve S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Patrick Bishop",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Gerald C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Andrew Lester",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Natasha G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Timothy Stewart",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Rachel O.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Nicole Carson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Paul B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Dustin Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Marvin M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Morgan Warren",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sherry C.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Melissa Riddle",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Samantha V.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Melanie Williams",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Elizabeth F.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Kimberly Caldwell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "George L.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Amy Smith DVM",
        "service_type": "Garage Door Off Track",
        "tech_name": "Monique C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Justin Martin",
        "service_type": "Garage Door Services",
        "tech_name": "Peter H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Kayla Alexander",
        "service_type": "Garage Door Opener",
        "tech_name": "Raymond M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Lisa Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Teresa L.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Glenn Martinez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Robert R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Paige Phelps",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gloria B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Clifford Kaiser",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "James W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Noah Hutchinson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Mia H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Destiny Knight",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Bonnie R.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "Tammy Neal",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jason G.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Debbie Cannon",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jacob S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Crystal Cantu",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jon L.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Kendra Kent",
        "service_type": "Garage Door Off Track",
        "tech_name": "Denise N.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-17",
        "review_rate": 5.0,
        "customer_name": "Blake Valenzuela",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rachel C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Martin Cole",
        "service_type": "Garage Door Opener",
        "tech_name": "James P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "John Young",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Travis M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Michael Villarreal",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michelle E.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Keith Parsons",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Shannon W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Kevin Wilson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joy G.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Arthur Dorsey",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Collin H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Krystal Burke",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Clarence L.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Diaz",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "John O.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Anita Sims",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Elizabeth G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "Jessica Griffith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ronald F.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Kevin Cobb",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kristina R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Randy Thompson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Benjamin M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Sally Tran",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Robert M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Seth Simmons",
        "service_type": "Garage Door Insulation",
        "tech_name": "Melanie V.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Thomas Kirby",
        "service_type": "Garage Door Installation",
        "tech_name": "Linda R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Heather Hayden",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Chad S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Connor Woodward",
        "service_type": "Residential Garage Door Services",
        "tech_name": "David A.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Jose Pineda",
        "service_type": "Garage Door Insulation",
        "tech_name": "Andrew M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Shelby Campbell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lisa A.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.8,
        "customer_name": "Laura Morrow MD",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Bethany S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Devin Mcgee",
        "service_type": "Garage Door Opener",
        "tech_name": "Cynthia B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Patricia Sawyer",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Bernard Warren",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kayla H.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Kenneth Hawkins",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jennifer P.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Timothy Johnston",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Richard M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Tina Gonzalez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Teresa H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Dillon Chang",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Linda T.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Joseph Larson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kimberly G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Lauren Durham",
        "service_type": "Garage Door Opener",
        "tech_name": "Courtney J.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Brandon Wilson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brianna S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Brittany Rose",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jennifer A.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Joanna Dickson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kimberly J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Wanda Douglas",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Janet F.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Samantha Chambers",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Natalie S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Mary Perez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ashley R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Walter Armstrong",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Matthew M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Suzanne Hale",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jessica F.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Samantha Robbins",
        "service_type": "Garage Door Opener",
        "tech_name": "Robert B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Kathy Taylor",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Andrea O.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Joshua Cook",
        "service_type": "Garage Door Off Track",
        "tech_name": "Zachary O.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Tina Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christine P.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Nicholas Browning",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Debra D.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Gary Reynolds",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Robert H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Sharon Ramirez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rodney C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Monica Horn",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kevin K.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Jennifer Liu",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mary W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Stephen Byrd",
        "service_type": "Garage Door Off Track",
        "tech_name": "Elizabeth C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Edward Garner",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Gregory D.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Julie Galloway",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Chad M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Robert Blevins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Molly S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Madeline Munoz",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Adam W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Kyle Juarez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Carl J.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.8,
        "customer_name": "Cameron Lopez",
        "service_type": "Garage Door Opener",
        "tech_name": "James S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Richard Lamb",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jennifer M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Abigail Jones",
        "service_type": "Garage Door Insulation",
        "tech_name": "Melinda T.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Timothy Peters",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Emily M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Maria Holmes",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michelle R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Ryan Tanner",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Austin G.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "George Hernandez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Denise S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Megan Archer",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joseph P.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Joseph Jarvis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Edward P.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Dawn Espinoza",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Natalie H.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Jo Bennett",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Judy M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Lisa Garcia",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeffrey M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "James Mann",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Nicholas O.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Charles Morrison",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robert P.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Gary Villarreal",
        "service_type": "Garage Door Opener",
        "tech_name": "Shannon C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Amber Oliver",
        "service_type": "Garage Door Insulation",
        "tech_name": "Patricia N.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Javier Kelley",
        "service_type": "Garage Door Opener",
        "tech_name": "Adrienne R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Beverly Phillips",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jason Y.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Michele Williams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Dawn O.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Jennifer Lowe",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jeffrey B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Benjamin Johnson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sheila R.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "William Holden",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Raymond D.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Stephanie Gardner",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mark B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Wanda Wilkinson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kristina H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.8,
        "customer_name": "Joshua Rich",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Pamela C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Anthony Miller",
        "service_type": "Garage Door Insulation",
        "tech_name": "Timothy R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Kristin Turner",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James T.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Frances Flores",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mark L.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Alyssa Pearson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amy A.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Morgan King",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Deanna M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Brandon Collins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Samantha S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Joshua Alexander",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Emily B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Brittany Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Kayla R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "April Guerrero",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Natalie W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Scott Williams",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Paul B.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Laura Underwood",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Peter F.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "Emily Juarez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jessica S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Harold Dennis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brittany B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Ashley Tran",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Daniel T.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Robert May",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Katherine L.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Brian Allen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Adam B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "Miranda Kelly",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Zachary J.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Marcus Lee",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brett M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Sydney Giles",
        "service_type": "Garage Door Insulation",
        "tech_name": "Melissa J.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Christina Mcfarland",
        "service_type": "Garage Door Services",
        "tech_name": "Danielle P.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Marisa Ford",
        "service_type": "Garage Door Installation",
        "tech_name": "Cynthia M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Jason Martinez",
        "service_type": "Garage Door Installation",
        "tech_name": "Michelle H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.8,
        "customer_name": "Rachel Johnson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Alejandra G.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Robert Chapman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lauren N.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Samantha Briggs",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Robert B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Erin Dillon",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Cynthia H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Kevin Baird PhD",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jason H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Michelle Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jonathan W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Cathy Fisher",
        "service_type": "Garage Door Services",
        "tech_name": "Zachary L.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Jackie Norton",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Laura M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Anthony Macdonald",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Roger Rivera",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Melissa C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Nicholas Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Daryl K.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Joseph Porter",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amanda M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Anthony Aguirre",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jacob T.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Paul Cruz",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "April L.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Mike Soto",
        "service_type": "Garage Door Off Track",
        "tech_name": "Evelyn L.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Janet Walter",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michelle P.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Yolanda Le",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Charles J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Beth Wilson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Crystal B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Steven Snyder",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amy T.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Johnson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Gina T.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Angela Mora",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Anna R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Kara Mcdonald",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "James W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-02",
        "review_rate": 5.0,
        "customer_name": "Douglas Wilson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Rachel N.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Lauren Horton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Charlene B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Matthew Reid",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Krista P.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Randy Bennett",
        "service_type": "Garage Door Opener",
        "tech_name": "Kimberly A.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Tyler Sutton",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Edwin L.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Summer Garrison",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Shannon M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Ricardo Reese",
        "service_type": "Garage Door Services",
        "tech_name": "James H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Cheryl Ellis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Julie P.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Heather Cruz",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James D.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Marissa May",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jessica B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Joshua Green",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joshua S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Daniel Ward",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Laurie P.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Katherine Stokes",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Corey T.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Diane Hernandez DVM",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tracy W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Virginia Brown",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Meagan S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Steven Baker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amanda H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Marcus Jones",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Patrick J.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Ricardo Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Charles B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Jacqueline Chapman",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael F.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Heather Dunlap",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ryan H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Christopher Miller",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stephanie P.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Sean Jones",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Patrick S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Kenneth Yang DDS",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David E.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Pamela Camacho",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael A.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Tanner Gallagher",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Diana M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Ashley Myers",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "William B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Maria Foley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Beth G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Anthony Santana",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Angela L.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Robert Dyer",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Crystal O.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Kristin Wells",
        "service_type": "Garage Door Insulation",
        "tech_name": "Randy N.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Jennifer Compton",
        "service_type": "Garage Door Insulation",
        "tech_name": "Katherine R.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "April Preston",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "George B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Lisa Austin",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Melissa S.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Nicole Johnson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Nicole H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Chad Mcdowell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lisa C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Alex Schneider",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christian B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Ryan Ortiz",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Erin T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Anthony Salazar",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brittany H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Brooke Griffith",
        "service_type": "Garage Door Services",
        "tech_name": "Amy C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Amy Morris",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lawrence R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Timothy Long",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christine M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Roberto Nelson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Darrell W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Timothy Skinner",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Rachel J.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Heather Williamson",
        "service_type": "Garage Door Opener",
        "tech_name": "Laura S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Thomas Hammond",
        "service_type": "Garage Door Repair",
        "tech_name": "Bradley C.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Melanie Wu",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "William Sawyer",
        "service_type": "Garage Door Installation",
        "tech_name": "Blake G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Tara Moore",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kelly V.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Dr. Margaret Robertson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Leah Z.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Savannah Clark",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jeffrey B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Sarah Gregory",
        "service_type": "Garage Door Installation",
        "tech_name": "Jasmin N.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Joshua Farrell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Norma M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Tracy Richardson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Monica R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Brendan Yang",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kevin A.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Jonathan Mcgee",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kathleen J.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Richard Coleman",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Catherine G.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Robert Golden",
        "service_type": "Garage Door Services",
        "tech_name": "Jon C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Ruth Ortiz",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Gabriel C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Stuart White",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Andrea S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Matthew Tate",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Scott E.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Susan Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ronald J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Sharon Butler",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Robert T.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Eric Ingram",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christopher J.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Cheryl Morris",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Marissa H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Andrew Taylor",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cassidy B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Antonio Miller",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Robin N.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Brandy Glenn",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "John M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Rhonda Lee",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Patrick H.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Jacob Reyes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Suzanne A.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Lori Salinas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Felicia E.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Thomas Martinez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sharon H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Todd Villarreal",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christina R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Donna Richardson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brenda T.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Courtney Lindsey",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Justin G.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Christopher Daniels",
        "service_type": "Garage Door Services",
        "tech_name": "Alexis H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Carrie Cruz",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael J.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "James Murray",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jesse B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Alisha Stewart",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Eric R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Michelle Mitchell",
        "service_type": "Garage Door Opener",
        "tech_name": "Joshua H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "John Mitchell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Stephen H.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "James White",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Mary Y.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Michael Aguilar",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Robert B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Katrina Phillips",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alexander K.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Angel Phillips",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Heather S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Brandon Morris",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Benjamin W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "Christina Edwards",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Eric C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Jennifer Lopez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joshua C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-22",
        "review_rate": 5.0,
        "customer_name": "Melanie Clark",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kylie M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Stacie Lee",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nancy S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Peter Nelson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Julie P.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Whitney Strickland",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "David H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Michelle Mason",
        "service_type": "Residential Garage Door Services",
        "tech_name": "William L.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Morgan Madden",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lawrence P.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Frank Swanson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Steve G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Karen Murray",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Steven S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Elijah Torres",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michael C.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Amy Kirk",
        "service_type": "Garage Door Services",
        "tech_name": "Amber C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "William Faulkner",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Barbara J.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "Robert Moses",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Bethany T.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "Joseph Gomez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cesar R.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-07",
        "review_rate": 5.0,
        "customer_name": "Robert Robinson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Justin A.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Jerry Kane",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ethan G.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Garrett Mcdonald",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Karen J.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Matthew Jones",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Vanessa D.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Sarah Anderson",
        "service_type": "Garage Door Installation",
        "tech_name": "Richard W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Courtney Martinez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Scott D.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Ashley Ramirez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Candice R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Mitchell Jennings",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Justin T.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Pedro David",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kara S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Thomas Wright",
        "service_type": "Garage Door Opener",
        "tech_name": "Richard O.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Andrew Meyer",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Heather N.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Heather Freeman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Regina P.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Jill Mcmillan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Charles A.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Ryan Davis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ryan S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Jennifer Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Alexa M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Allison Vasquez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Robert B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "James Ramsey",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ashley P.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Marcia Vega",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ashley B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Thomas Ibarra",
        "service_type": "Garage Door Services",
        "tech_name": "Tracey G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "Mr. Steven Miller",
        "service_type": "Garage Door Repair",
        "tech_name": "Kimberly N.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-22",
        "review_rate": 5.0,
        "customer_name": "Lisa Bush",
        "service_type": "Garage Door Repair",
        "tech_name": "George W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Lisa Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Juan M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Daniel Spencer",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jennifer A.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Jennifer Wilson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Donna M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "David Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Brian W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Victoria Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Daniel R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Richard T.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "David Harris",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Stephen W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Patrick White",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James A.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Ms. Hannah Mcconnell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Michael Todd",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Scott O.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Jason Mann",
        "service_type": "Garage Door Insulation",
        "tech_name": "Autumn H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "Amy Norton",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Pamela S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Rodney Thomas",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert T.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Ashley Martin",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Melinda J.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Christine Hernandez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Peter H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Melissa Carpenter",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Gerald T.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Jared Blanchard",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jason H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Joshua Murray",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kelly P.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Craig Washington",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cody B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Jason Williamson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Patrick C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Andrew Davis",
        "service_type": "Garage Door Opener",
        "tech_name": "John L.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Heather Cantu",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Vincent C.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Stacie Vaughan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tasha G.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Regina White",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Paul T.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Mrs. Jamie Anderson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Erica M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Alyssa Perry",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Thomas A.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Mrs. Marie Holland",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Coffey",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christine H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Melissa Horton",
        "service_type": "Garage Door Off Track",
        "tech_name": "Elizabeth S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Tina Jimenez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sean L.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Nichole Wilkerson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Richard W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Robert Bray",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Luis W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Megan Perkins",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joseph N.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Joe Schneider",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Katherine D.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Dustin Hines",
        "service_type": "Garage Door Off Track",
        "tech_name": "Timothy B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Amber Casey MD",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Scott B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Derek Morris",
        "service_type": "Garage Door Off Track",
        "tech_name": "Vanessa L.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Andrew Gutierrez",
        "service_type": "Garage Door Repair",
        "tech_name": "Erika J.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Michael Yates",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Nicole E.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Edward Randolph",
        "service_type": "Garage Door Installation",
        "tech_name": "Elizabeth R.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Judith Reed",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Erica M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "David Price",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Rachael C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Jay Guerrero",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anna M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Taylor Davis",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Raymond P.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Katherine Morris",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anthony J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Danielle Schmidt",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Gregory M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Kelly",
        "service_type": "Garage Door Opener",
        "tech_name": "Joshua R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Matthew White",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Clayton D.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Kelli Herrera",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Nicholas P.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.8,
        "customer_name": "Andrew Andrade",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Matthew B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Heidi Weaver",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Carmen G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Paula Price",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jeffrey R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "William White",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Melissa G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Cynthia Keller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "John J.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Hailey Fischer MD",
        "service_type": "Garage Door Installation",
        "tech_name": "Melissa B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Joseph Cox",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Richard L.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Linda Hughes",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Claudia K.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Gregory Ellis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jennifer B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Tara Robinson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sheila B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Rachel Zuniga",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Vanessa Schroeder",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Douglas L.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Melissa Wiley",
        "service_type": "Garage Door Repair",
        "tech_name": "Julie H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Savannah Smith",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Barbara M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-23",
        "review_rate": 5.0,
        "customer_name": "Gary Scott",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joseph J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Brian Johnson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jose H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Mathew Diaz",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ronald H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Jonathan Anthony",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ashley P.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Anthony Moore",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Greg M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Samantha Ayala",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "John Carlson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michelle F.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Clayton Young",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nicholas W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Douglas Li",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michelle B.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Jennifer Walker",
        "service_type": "Garage Door Services",
        "tech_name": "Alyssa W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Susan Black",
        "service_type": "Garage Door Opener",
        "tech_name": "Amy M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Angela Duran",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Courtney T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Tammy Tyler",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Emily S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Andrea Lee",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Curtis S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Jonathan Lawson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kimberly F.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Darlene Mendoza",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Elizabeth H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Paul Vincent",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael K.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Laura Hicks",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Diane O.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Juan Adams",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Destiny L.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Jordan Kennedy",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jason M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.8,
        "customer_name": "Cheyenne Phelps",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Robert H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Jackson Wright",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kathleen V.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Mary Mcmahon",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Susan W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Lisa Vasquez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Paul H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Gary Lowe",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joel P.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Dwayne Rowe",
        "service_type": "Garage Door Services",
        "tech_name": "Richard H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Victoria Rivas",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Teresa H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Sergio Bowman",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brianna G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Patricia Rogers",
        "service_type": "Garage Door Off Track",
        "tech_name": "Douglas D.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Kayla Oliver",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Rebecca M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Shawn Martinez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Linda T.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Gregory Hahn",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sarah W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Katherine Perez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "James G.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Matthew Campbell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "William N.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Javier Matthews",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christopher S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Nathan Petersen",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jennifer B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Laura Williams",
        "service_type": "Garage Door Repair",
        "tech_name": "Krystal H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Mrs. Meghan Ruiz DDS",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael E.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Heather Mccarthy",
        "service_type": "Garage Door Opener",
        "tech_name": "Frank T.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Samantha Wang",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sylvia W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Jessica Davis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michelle D.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Cameron Mcconnell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Leslie K.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Nicholas Fuller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Chad J.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.8,
        "customer_name": "Lori Jackson",
        "service_type": "Garage Door Off Track",
        "tech_name": "John G.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Troy Nicholson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mason W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Ann Phillips",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kent D.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Susan Morales",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Julie C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Adam Jordan",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John D.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Shannon Henry",
        "service_type": "Garage Door Insulation",
        "tech_name": "Rick B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Jeanne Edwards",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Louis C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Dr. Daniel Brewer",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Erica S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "Steven Clay",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amanda B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "David Richardson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Wesley Maxwell",
        "service_type": "Garage Door Opener",
        "tech_name": "Paul E.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Michelle White",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jamie P.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Anita Kelley",
        "service_type": "Garage Door Off Track",
        "tech_name": "Karla O.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Catherine Price",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Robert D.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Debra Berg",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Anthony J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Michael Davis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Fred B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Victor Ramirez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ryan D.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Calvin Nunez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Adam A.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Brenda Stephens",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "William Frey",
        "service_type": "Garage Door Opener",
        "tech_name": "Joe U.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Emily Webster",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Samantha H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-17",
        "review_rate": 5.0,
        "customer_name": "John Brown",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nicholas S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Rhonda Kim",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kelly G.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "William Robinson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Edwin C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Mitchell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kristin R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Kenneth Hall",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mark H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.8,
        "customer_name": "Samantha Scott",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Betty L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Bailey Gibson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christopher H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Regina Chase",
        "service_type": "Garage Door Opener",
        "tech_name": "Sarah S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Sarah Underwood",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tyler C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Christopher Mcmahon",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Patrick T.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Jeremy Perez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Rebecca D.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Kari Ross",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robert W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Tina Andrade",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mike D.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Stephanie Turner",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jamie C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Dean Ruiz",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David B.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Daniel Lee",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher F.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Kevin Dennis",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Thomas S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Brittney Galloway",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jill S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Andre Williams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ashley P.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Stacy Hunter",
        "service_type": "Garage Door Repair",
        "tech_name": "Timothy F.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Maureen Allen",
        "service_type": "Garage Door Repair",
        "tech_name": "Nicholas G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "Richard Hall",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ronald E.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Kendra Ford",
        "service_type": "Garage Door Repair",
        "tech_name": "Andrea W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Abigail Walker",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Nicole Davis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Cynthia C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Kimberly Collins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nancy M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "John Garcia",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kenneth R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Leslie Gilbert",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Benjamin H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Steven Brooks",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Loretta K.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Tracy Davis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Katie B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Shelby Peterson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brian H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Cody Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sandra H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Joseph Olsen",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jennifer F.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Francis Little",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michael S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "Evan Thompson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tiffany S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Douglas Gross",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Janet S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.8,
        "customer_name": "Alexandra King",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Audrey L.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Holly Morgan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Samantha B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Jeanne Dodson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Danielle S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Amy Wilson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Peter T.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Kevin Robertson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Norman H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Nicole Williams",
        "service_type": "Garage Door Repair",
        "tech_name": "Joshua S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Julie Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Mary F.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Laurie Barton",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Eric L.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Nancy Stevenson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jerry F.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Mr. Adam Wilson MD",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Elizabeth L.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Maurice Diaz",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Mark N.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Holly Ferguson",
        "service_type": "Garage Door Services",
        "tech_name": "Amy R.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Brian Robertson",
        "service_type": "Garage Door Services",
        "tech_name": "Michelle W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Curtis Castaneda",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nancy G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Kristy Cowan",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "David C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "George Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Hannah M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Joshua Schultz",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Justin D.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Bruce Santos",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Zachary B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Dustin Dickerson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Timothy N.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Benjamin Sullivan",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "William C.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Juan Ellis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Shawna A.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Jonathan Hardin",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Clinton C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "Mark Wilson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mary T.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Gregory Cruz",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Julie C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Phillip Poole",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Rebecca C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Melanie Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Janice C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Luis Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Thomas A.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "Thomas Gallagher",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Madison R.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "David Perry",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Mary V.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Donald Gomez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Paul G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Kristina Delgado",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "James L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Traci Mills",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alison B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Daniel Delacruz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kathy S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Kiara Wright",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Samuel D.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Peter Barrett",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Susan H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Michael Anthony",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ricardo R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Julie Cox",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Cassie S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "John Stephens",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Laura E.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Gregg Martin",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lisa S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Philip Nash",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael F.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "Scott Chen",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Steven W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Mrs. Bonnie Kramer",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ricky P.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Stanley Garcia",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Adam G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Corey Castaneda",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Jessica Gonzalez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Melinda H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Jeremy Petersen",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Rickey B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Robert Patrick",
        "service_type": "Garage Door Services",
        "tech_name": "Nichole K.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "John Chaney",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Anthony W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Anthony Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michele H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Chris Reyes",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jonathan D.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Tiffany Parks",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Shawn T.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "Ryan Rogers",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Natasha W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Amy Mejia",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robin A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Whitney Daniels",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jennifer T.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Tyler Dunn",
        "service_type": "Garage Door Opener",
        "tech_name": "Brittany T.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Billy Jenkins",
        "service_type": "Garage Door Services",
        "tech_name": "Erin M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Shawn Aguilar",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Molly R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "Alejandra Payne",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joseph M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Denise Carson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Matthew B.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Richardson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Heather R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Rachel Mendoza",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ruth L.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Angelica Hart",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brandon F.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.8,
        "customer_name": "Stephen Campbell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alyssa M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Suzanne Phillips",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Adam B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Erin Hurst",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brian W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Cathy Garcia",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Rachel A.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Abigail Hahn",
        "service_type": "Garage Door Opener",
        "tech_name": "Shannon S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Sarah Mueller",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ruth M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Brian Horton",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Abigail E.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Angela Glass",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Susan P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Ryan Cooper",
        "service_type": "Garage Door Installation",
        "tech_name": "Cameron G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Patricia Malone",
        "service_type": "Garage Door Installation",
        "tech_name": "Jason B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.8,
        "customer_name": "David Shaffer",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James L.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Jacob Velez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jasmine S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Nancy Moore",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sean J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Katrina Hudson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kyle C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.8,
        "customer_name": "Amanda Petersen",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mason W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Bradley Camacho",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sean B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "William Barr",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brent A.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Mitchell Scott",
        "service_type": "Garage Door Services",
        "tech_name": "Madison R.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Nathan Kelly",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Vincent C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Barbara Green",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joshua J.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Natalie Price",
        "service_type": "Garage Door Repair",
        "tech_name": "Timothy B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Crystal Morales",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Margaret W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Anthony Simon",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Ryan Yoder",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Susan B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Jason Golden",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jessica A.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Steven Koch",
        "service_type": "Residential Garage Door Services",
        "tech_name": "David K.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Barbara Mueller",
        "service_type": "Garage Door Insulation",
        "tech_name": "Anna J.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Kristopher Walsh",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sara W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Jose Davis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kellie G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Kevin Payne",
        "service_type": "Garage Door Repair",
        "tech_name": "Jennifer B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Rachel Wilson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kimberly R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Yvonne Kennedy",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sandy M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Alan Nguyen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jesse A.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Douglas Hughes",
        "service_type": "Garage Door Services",
        "tech_name": "Jacob M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Benjamin Mcconnell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Madison R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Lauren Mcdonald",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Eric F.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Rebecca Houston",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amber O.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Michael Hansen",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ryan M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Kristin Williams",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "James G.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Watson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Adrienne C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Katherine Mayo",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Taylor H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Devin Guerra",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kelly B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Amanda Garcia",
        "service_type": "Garage Door Services",
        "tech_name": "William H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Alison Nelson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Geoffrey M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Alex Edwards",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Angela E.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Brandon Barrett",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Mark A.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Karen Hoffman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christina V.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Keith Allen",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Thomas K.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Mark Hill",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Timothy W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Kevin Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Steven W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Sandra Harris",
        "service_type": "Garage Door Off Track",
        "tech_name": "Melissa S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Amy Johnson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Daniel D.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Ronald Smith Jr.",
        "service_type": "Garage Door Installation",
        "tech_name": "Tasha G.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Melissa Morris",
        "service_type": "Garage Door Opener",
        "tech_name": "Roberto J.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Miranda Russell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Julie G.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Jasmine Lee",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Courtney H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Troy Evans",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Amanda S.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Megan Miller",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kathleen S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Kevin Barker",
        "service_type": "Garage Door Installation",
        "tech_name": "David C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Nathan Short",
        "service_type": "Garage Door Opener",
        "tech_name": "John O.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Renee Mcfarland",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Zachary J.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Natasha Austin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ashley B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Timothy Bailey",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Scott Morris",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "John A.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Amy Ramirez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christina J.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Linda Martinez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Victoria W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Raymond Barton",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jessica C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Brandon Tucker",
        "service_type": "Garage Door Opener",
        "tech_name": "Jessica H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Tabitha Li",
        "service_type": "Garage Door Services",
        "tech_name": "Rebekah H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Ruben Villa",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephanie W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Wanda Farrell",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Matthew M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Maria Moran",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Dawn W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Tamara Dean",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Antonio R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "Richard Lopez",
        "service_type": "Garage Door Services",
        "tech_name": "Scott H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Sarah Haas",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lisa S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Hannah Skinner",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sharon W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Edgar Kennedy",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joseph G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Patrick Johnson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Chad B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Connor Thornton",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stephanie A.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "Michael Ballard",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Laura R.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "John Steele Jr.",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Steven M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Mr. Curtis Jackson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Angela G.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Kelly Bass",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Denise C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Stephen Preston",
        "service_type": "Garage Door Installation",
        "tech_name": "Ryan C.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Kevin Parker",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Maria H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Andrew Hopkins",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Robert S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Alejandro Grant",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jason L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Jonathan King",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lisa S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Kevin Wells",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Matthew R.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Michelle Adams",
        "service_type": "Garage Door Off Track",
        "tech_name": "Natasha W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Katrina Wells DVM",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kimberly P.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Thomas Fry",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicholas J.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Charles Hudson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jennifer R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Scott Rivera",
        "service_type": "Garage Door Installation",
        "tech_name": "Jacob P.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Robert Scott",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Denise B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Lauren Guerrero",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Melanie M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Lawrence Thomas",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Travis C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Richard Clark",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jason B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Theresa Rivera",
        "service_type": "Garage Door Insulation",
        "tech_name": "Courtney R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Lauren Lee",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brenda B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Nicholas Martinez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Paul R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Haley Rogers",
        "service_type": "Garage Door Opener",
        "tech_name": "Monica S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Mr. Roger Lewis DVM",
        "service_type": "Garage Door Repair",
        "tech_name": "Tracey C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Emily Peterson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lisa A.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Gregory Wright",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kyle R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Vanessa Ray",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Martha G.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "Kent Phillips",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Angel B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Tara Adams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ryan R.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Derrick Knight",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ashley B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Larry Mcguire",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jane R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Kylie Marshall",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Rhonda A.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Erica Rivas",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Allen H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Marissa Morris",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Kelly Rodriguez",
        "service_type": "Garage Door Installation",
        "tech_name": "Jacob L.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Mark Barber",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jon W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Monica Wade",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kevin C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Samuel Vasquez",
        "service_type": "Garage Door Opener",
        "tech_name": "Christie F.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Nicholas Mcdonald",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Nathan B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Madison Lawrence",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jennifer W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Jessica Wells",
        "service_type": "Garage Door Insulation",
        "tech_name": "Patricia W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Jason Woods",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Carol N.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Joshua Klein",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kim G.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Jacob Yoder",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kevin C.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Anthony Rangel",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kenneth T.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Kayla Roth",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Maria W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Michael Wilkins",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rachel D.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Connie Paul",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Samuel H.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Richard Conley",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Timothy G.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Joshua Garcia",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Alan C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Richard Chen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jessica W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Tyler Cooper",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Julie P.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "Margaret Flowers",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "James J.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Donna Roth",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ebony L.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-17",
        "review_rate": 5.0,
        "customer_name": "Adam Murillo",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Peter H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Rita Campbell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lawrence V.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "James Bauer",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Justin C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Ward",
        "service_type": "Garage Door Services",
        "tech_name": "Jennifer F.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Cheryl Mitchell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "William O.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Robert Manning",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brenda H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Amy Flores DVM",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Keith R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Christina Griffin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Marcus K.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Kaitlyn Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Donna A.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Shawn Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Daniel D.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Destiny Harris",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Holly M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "John Stewart",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kimberly R.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Hannah Fowler",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Ruth B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Megan Pham",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Billy C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Ronald Novak",
        "service_type": "Garage Door Installation",
        "tech_name": "Christina M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Lee Clark",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Martin M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Michelle Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brenda O.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "David Vang",
        "service_type": "Garage Door Opener",
        "tech_name": "Brian B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Tonya Lee",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joanna L.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Patrick Liu",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Adam Bass",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Valerie D.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Erika Patterson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dawn V.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Linda Cox",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lisa B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Curtis Johnston",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Allison W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Tyler Jefferson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Timothy C.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Glenda Keller",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ryan M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "Jason Alvarez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Stanley D.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "David Long",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Kim Dean",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alexis D.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Lisa Nicholson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christopher F.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Erik Rodriguez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amy K.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Vanessa Hughes",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lori N.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Mackenzie Williams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Susan M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Deborah James",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Taylor S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Ryan Schwartz",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christina W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Gary Adams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Cameron G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Diana Cooper",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kara S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Amber Cruz MD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Beth P.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Jennifer Whitaker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kyle L.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Taylor Gentry",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Austin B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Darren Walker",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sean B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Holly Cook MD",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Paul F.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Christine Walker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lauren M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Sharon Patterson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amy M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Deanna Blevins",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Allen R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Noah Gill",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "David H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Katie George",
        "service_type": "Garage Door Installation",
        "tech_name": "John F.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Bobby Young",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Monica P.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Lisa Ramos",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jessica B.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Michael Clark",
        "service_type": "Garage Door Off Track",
        "tech_name": "Gregory L.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Kristen Barron",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joseph B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "James Hurst",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Paula D.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Christopher Foster",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kelly W.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Ashley Blackwell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael T.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Walter Vazquez",
        "service_type": "Garage Door Repair",
        "tech_name": "Vernon F.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Ryan Rogers",
        "service_type": "Garage Door Opener",
        "tech_name": "Donald C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Gerald Fowler",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anna S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Danielle Sutton",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Nicholas Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Steven H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Angela Lang",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tracey F.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Alice Atkinson",
        "service_type": "Garage Door Installation",
        "tech_name": "William J.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Bobby Ward",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael J.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Matthew Gordon",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Andrea P.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Jared Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Melissa C.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Michael Escobar",
        "service_type": "Garage Door Repair",
        "tech_name": "Christopher W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Gabriel Cox",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Linda M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Dominic Gonzalez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Monica R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Ms. Jessica Curry",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Taylor T.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Michelle Kim",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Rebecca D.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Morgan Hawkins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jason W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Bryan Clark",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Stacy D.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Jennifer Williams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Robert R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-05",
        "review_rate": 5.0,
        "customer_name": "Wendy Harding",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Victoria K.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Daniel Miller",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Alyssa Gutierrez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Megan M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Nathan Morgan",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Larry B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Linda Flores",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jessica B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Bruce Newman",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jeffrey L.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Nicholas Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Jessica A.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Alfred Rodriguez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Keith R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Mark Allen",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mark G.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Mr. Dwayne Ibarra",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brian G.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Thomas Jones",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christina A.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Ryan Sanchez",
        "service_type": "Garage Door Services",
        "tech_name": "Troy H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Mark Nicholson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Todd W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Michael Gomez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sharon B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Regina Burton",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mark B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Donna Johnson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michelle S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.8,
        "customer_name": "Barbara Rose",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jason L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Shaun Garcia",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Virginia P.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Steven Alexander",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "James V.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Linda Rodriguez",
        "service_type": "Garage Door Services",
        "tech_name": "Darlene B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-03",
        "review_rate": 5.0,
        "customer_name": "Amanda Bishop",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Nicolas B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Linda Duffy",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jesus G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Andrea Giles",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeffrey S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Thomas Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Dennis Harris",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Nicole F.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Brittany Davis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert E.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Kelly Ellis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jeffrey K.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Kendra Henderson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tabitha I.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Nicholas Pugh",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Holly H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Nicole Davis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Linda P.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Michael King",
        "service_type": "Garage Door Installation",
        "tech_name": "Sarah E.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Perry Price",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Daniel M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Philip Underwood",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kevin G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Matthew Rodriguez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kari P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Benjamin Rodriguez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joseph R.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Anna Young",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jeremy P.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Michelle Jones",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Rebecca P.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Margaret Newton",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jessica C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Darlene Davis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "David C.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Lisa Martinez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Andrew T.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Edward Norman",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "David H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Rhonda Wilson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Matthew B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Philip Boyer",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christopher G.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Lindsey Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Richard M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Melissa Bartlett",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Deborah S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Collin Taylor DVM",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Bruce C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Michael Anderson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michael T.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Ronald Scott",
        "service_type": "Garage Door Services",
        "tech_name": "Erin E.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Cesar Love",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Emily S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Sonya Rice",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kayla C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "William Morris",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Darlene H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Donald Lewis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Natalie B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Carrie May",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Maria H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Ryan Soto",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jessica B.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Richard Deleon",
        "service_type": "Garage Door Off Track",
        "tech_name": "Travis S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Emily Curry",
        "service_type": "Garage Door Installation",
        "tech_name": "Jesse W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "James Gillespie",
        "service_type": "Garage Door Services",
        "tech_name": "Adam K.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Jessica Leon",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Maureen R.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Adam Moreno",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mark P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Marc Campbell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Whitney H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Kristy Sullivan",
        "service_type": "Garage Door Opener",
        "tech_name": "Thomas A.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Michael Ruiz",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Richard S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Gregory Stewart",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeffrey B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Angelica Williams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kevin A.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Brenda Brown",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Dennis M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Randy Cooke",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Richard C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Tanya Peters",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Robert S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Deborah Reynolds",
        "service_type": "Garage Door Off Track",
        "tech_name": "Juan S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Sherry Ruiz",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joshua M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Sherri Myers",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jacob W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Timothy Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Curtis G.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Bryan Gordon",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Charles R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Penny Kennedy",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tammy Y.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Zachary Dean",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Justin P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-14",
        "review_rate": 5.0,
        "customer_name": "Dylan Hays",
        "service_type": "Garage Door Insulation",
        "tech_name": "Shane C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Amber Bauer",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christine C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Betty Fox",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jeremy V.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Joseph Barnett",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Amy S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Matthew Hart",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Meredith B.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Linda Gilmore",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Rickey P.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Gary Mitchell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Julie D.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-10",
        "review_rate": 5.0,
        "customer_name": "Lori Davis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ashley R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Rebecca Forbes",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kevin A.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Alex Holmes",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jeremy J.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-29",
        "review_rate": 5.0,
        "customer_name": "Jenna Cortez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alexa B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Sarah Stewart",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jason G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Natasha Murphy",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ashley W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Donna Rivera",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Debra B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Shelly Spears",
        "service_type": "Garage Door Services",
        "tech_name": "Anthony S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Keith Ritter",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Katelyn R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Rachael Wiley",
        "service_type": "Garage Door Opener",
        "tech_name": "Kelly F.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Sara Young",
        "service_type": "Garage Door Repair",
        "tech_name": "Sheila P.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Jamie Evans",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kimberly B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "David Hendricks",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mary B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Michael Williams",
        "service_type": "Garage Door Installation",
        "tech_name": "Tiffany P.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Rebecca Jackson",
        "service_type": "Garage Door Services",
        "tech_name": "Craig M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Kyle Moore",
        "service_type": "Garage Door Installation",
        "tech_name": "Christine H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Joshua Garcia",
        "service_type": "Garage Door Installation",
        "tech_name": "Rhonda S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "John Wright",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Rodney T.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "David Marshall",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Larry T.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Logan Sullivan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Jeffery Cox",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kelly S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "George Chase",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tracy N.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Douglas Salinas",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Patrick C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Richard Rivera",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mary M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "James Mason",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Eric P.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Karen Haney",
        "service_type": "Garage Door Repair",
        "tech_name": "Seth G.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Ashley Cox",
        "service_type": "Garage Door Off Track",
        "tech_name": "Bailey J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Mary Wilson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Wyatt W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Xavier Roberts",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Julia K.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Deanna Ward",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jerry B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-05",
        "review_rate": 5.0,
        "customer_name": "Michael Berry MD",
        "service_type": "Garage Door Opener",
        "tech_name": "Eric R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Zachary Wood",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Susan W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Rodney Horn",
        "service_type": "Garage Door Off Track",
        "tech_name": "David P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Dr. Michelle Sawyer PhD",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert G.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Adam Drake",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jose S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "Cory Ramirez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Cassandra C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Nancy Martin",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Stephanie Thompson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Veronica B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Jeremy Cruz",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Katherine E.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Ryan Ashley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Danielle M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Tina Patterson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Katie R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Brandon Nelson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Caroline S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Victoria Mccarthy",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joshua B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Dale Ford",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Patricia A.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Sarah Bowers",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Valerie E.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Kaylee Daniel",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Steven G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Andrew Gomez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Laura R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Nathaniel Long",
        "service_type": "Garage Door Off Track",
        "tech_name": "Erin D.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Ho",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kathy J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Kelly Stafford",
        "service_type": "Garage Door Opener",
        "tech_name": "Jessica P.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Bryan Moran",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Theresa M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Megan Hernandez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Vernon G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Anna Hill",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Donna W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Barbara Ponce",
        "service_type": "Garage Door Opener",
        "tech_name": "Scott W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Antonio Hopkins",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ronald H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.8,
        "customer_name": "Jessica Ferguson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Melissa S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Joseph Bryant",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Evan R.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Laurie Rice",
        "service_type": "Garage Door Insulation",
        "tech_name": "Victoria W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Joseph Hughes",
        "service_type": "Garage Door Off Track",
        "tech_name": "Crystal H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Maxwell Huynh",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Nicole Walter",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anthony G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Lauren Oliver",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Autumn J.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Jennifer Lam",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kimberly R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Dana Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Cynthia W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Leslie Sandoval",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kimberly S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Michael Ellis DDS",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Virginia H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Erica Byrd",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Amy S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Pamela Holmes",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Benjamin R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Chad Berry",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "John W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Derek Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Dustin N.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Louis Pope",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Blake H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Mr. Nicholas Taylor",
        "service_type": "Garage Door Repair",
        "tech_name": "Hannah S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Charles Murray",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Austin S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Daniel Bauer",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joseph C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Linda Simmons",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joshua S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Matthew Pierce",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Hannah R.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Travis Harris",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Julie C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Michael Hall",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Andrea G.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Julie Baxter",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sandra V.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Derek Turner",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Larry D.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Jonathan Garner",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael F.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Jacob Little",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tyler A.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Scott Houston",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jessica C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Alan Lopez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "John G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Darren Brooks",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Andrea L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Stacey Smith",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Miguel V.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-08",
        "review_rate": 5.0,
        "customer_name": "Jeremy Orozco",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Stephen T.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.8,
        "customer_name": "Jaclyn Thompson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christina L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Cody Odonnell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Roy W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "Michael Morgan",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Colleen Y.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Kristen Petersen",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David H.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Hunter Sexton",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brian L.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Jeff Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Nancy D.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Christina Copeland",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mary S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Samantha Bean",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Wendy F.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Lori Harris",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Alicia W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Thomas Oneal",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jane J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Rachel Cross",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jasmine D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Colin Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mindy A.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Keith Houston",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephanie M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Kathleen Thompson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sara C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Vanessa Thomas",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "John S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Christopher Blankenship",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Paula B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Linda White",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christopher H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Nathan Carson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alexandra W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Sherry Spencer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gina W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Beth Kelly",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brittany S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Hannah Herrera",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Dalton O.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Dustin Thomas",
        "service_type": "Garage Door Opener",
        "tech_name": "Rodney R.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-29",
        "review_rate": 5.0,
        "customer_name": "Kevin Young",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Justin H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Nicholas Williams",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jessica E.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Amanda Carter",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Randy R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Shelley Mercer",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael D.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Megan Walsh",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Louis H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Belinda Miller",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Rachel H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Daniel Huynh",
        "service_type": "Garage Door Insulation",
        "tech_name": "Melissa H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Edward Foley",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ashley W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Lisa Randolph",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jill R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Michelle Burns",
        "service_type": "Garage Door Insulation",
        "tech_name": "Dana W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Anna Ramirez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jillian G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Mark Goodwin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Caleb S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Brett Montoya",
        "service_type": "Garage Door Opener",
        "tech_name": "Timothy D.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "David Morris",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jeffrey K.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Matthew Russell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Carolyn B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Sylvia Jones",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael O.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Sarah Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Jessica E.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Melissa Middleton",
        "service_type": "Garage Door Off Track",
        "tech_name": "Autumn R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Jesse Carr",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Andrew F.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Gary Page",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mark T.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Lauren Morales",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "John S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Scott Conley",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brian R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Christian Macdonald",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Johnathan S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Henry Peters",
        "service_type": "Garage Door Insulation",
        "tech_name": "James S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "April Taylor",
        "service_type": "Garage Door Installation",
        "tech_name": "Katelyn W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Troy Mays",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Donald C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Thomas Guzman",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Matthew V.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Zachary Wilson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jacob P.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Eric Rojas",
        "service_type": "Garage Door Services",
        "tech_name": "Mark R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Mariah Phillips",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brandy C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Shirley Mason",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amanda R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Jean Thomas",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brett C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "Vanessa Harmon",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joseph W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Angela Gomez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Aaron W.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Jordan Stephens",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Blake L.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Alexis Moody",
        "service_type": "Garage Door Track Repair",
        "tech_name": "John C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Heather Koch",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mary M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Robert Pruitt",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Veronica W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Kimberly Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nicole M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-06",
        "review_rate": 5.0,
        "customer_name": "Micheal Martin",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Molly M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Barbara Cruz",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Gary W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Maria Reyes",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kenneth J.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "William Hamilton",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Julie T.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Kimberly Harris",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael K.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "David Chapman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Stacy F.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Erin Hicks",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Laurie S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Donna Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tom R.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Brandi Sanchez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Crystal D.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Amanda Garcia",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Benjamin T.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Cassandra Frost",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Derrick J.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Timothy Daniel",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Greg M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Amber Allen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeremiah G.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Cindy Osborne",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joanna S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Joy Mccoy",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Megan S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Victor Hood",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Matthew M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Jessica Perez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Steven M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Laura Evans",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Steve H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "William Walker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Colin C.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Scott Huffman",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Carl S.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Kimberly Robinson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christine P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Paula Reid",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Aaron S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Nicole Martin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tiffany S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Leah Martinez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jasmine S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Stephanie Mayer",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nicholas H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Kimberly Rhodes",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Shelly L.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Russell Davis",
        "service_type": "Garage Door Services",
        "tech_name": "Dennis M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Beth Bowman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kimberly M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Christina Mendez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Angela G.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Gina Buchanan",
        "service_type": "Garage Door Installation",
        "tech_name": "Martha C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Nicole Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Maurice G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Luis Santiago",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Justin H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Karen Wolfe",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christian N.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Margaret Tapia",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sheri R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Rachael Cook",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jenny D.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Lewis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Linda F.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Brooke Carson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brian J.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Allen Foster",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tony L.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Justin Wong",
        "service_type": "Garage Door Repair",
        "tech_name": "William P.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Christopher Hunt",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Elizabeth S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Kelli Brown",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Angela P.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Tracy Mccoy",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sharon K.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Patrick Harper",
        "service_type": "Garage Door Repair",
        "tech_name": "Christina H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Dustin Sloan",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Benjamin G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "Christine Brennan",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Seth Pace",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Courtney K.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Matthew Castillo",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Daniel C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Ronald Coleman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Felicia G.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Misty Lewis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jennifer M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Kyle Arias",
        "service_type": "Garage Door Installation",
        "tech_name": "Christopher G.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Vanessa Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Leslie B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Bruce Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Amber Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Shelly M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Emily Lowe",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Linda Lopez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Melissa S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Amanda Morton",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Raymond G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Robin Moody",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Danielle C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Alec Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "April M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Lee White",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "William S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Todd Caldwell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joyce B.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Samuel Pierce",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Katherine L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Katelyn Macdonald",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Leslie F.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Haley Mcgee",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brenda D.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Thomas Pierce",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Julie B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Duane Cabrera",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David P.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Tina Gomez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Alexandra W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Gerald Hall",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Rachel D.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Kathleen Gibson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Karen B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "John Wood",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tony M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Brittany Morris",
        "service_type": "Garage Door Services",
        "tech_name": "Brenda D.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Joseph Santos",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stephanie N.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Ronald Gray",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Spencer V.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Rebecca Sherman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "William H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Jenna Gill",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jason Y.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Jeremiah Russo",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michelle V.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Rachel White",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Trevor S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Melissa Brown",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Andrew C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Stephanie Burns",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Melanie P.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "James Aguilar",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jamie A.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Robert Perez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Karen C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "Kurt Hodges",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "William H.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Melissa Hall",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sandra A.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Amy Watts",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lauren S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "William Davis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Corey V.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Matthew Carr",
        "service_type": "Garage Door Insulation",
        "tech_name": "Pamela K.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Johnny Morris",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Zachary Z.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Mr. Ryan Mitchell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Matthew B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "Ann Collins",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Barry F.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Melanie Casey",
        "service_type": "Garage Door Services",
        "tech_name": "Anna G.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Tammy Knox",
        "service_type": "Garage Door Repair",
        "tech_name": "Stacy D.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "Anna Thompson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lawrence C.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Benjamin Lee",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "David S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Joanna Stevenson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Justin Vargas",
        "service_type": "Garage Door Insulation",
        "tech_name": "Barbara S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Michael Murphy",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ashley S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Amanda Maldonado",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Darren B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Jamie Mendez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael G.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Amber Conley",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alexandra G.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Donald Davis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Carl C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Kelly Mccarty",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Roberta V.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Ryan Chavez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Amber M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Catherine Hill",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jennifer C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "John Hughes",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Anita A.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Mary Palmer",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amber R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "Madeline Scott",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Noah B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Laura Richards",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Larry I.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Monique Barton",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Neil A.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Katelyn Li",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sonya J.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-23",
        "review_rate": 5.0,
        "customer_name": "Toni Bell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Anthony S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Jessica Diaz",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sarah G.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Edward Hanna",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sheri G.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Patel",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Timothy W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Brandon Bowen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Whitney M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Mrs. Julie Washington",
        "service_type": "Garage Door Repair",
        "tech_name": "Elizabeth C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Monique Johnson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John L.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Diane Short",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Melissa W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Tiffany French",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Bethany N.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Suzanne Walker",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kevin T.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Bryant",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kevin J.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Charles Chavez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Laura Y.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Oscar Beard",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amy W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Renee Davies",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ralph B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Andrew Griffin",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeffrey M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Fernando Moore",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joshua M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Alejandro Mendez",
        "service_type": "Garage Door Repair",
        "tech_name": "Luis S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Andrew Roberts",
        "service_type": "Garage Door Opener",
        "tech_name": "David M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Sophia Flores",
        "service_type": "Custom Garage Door Design",
        "tech_name": "John P.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Timothy Moody",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michelle L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Christina Nicholson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jason L.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Jennifer Frost",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "James Gonzalez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mary N.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Kenneth Dunlap",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "David B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Carl Monroe",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Alyssa D.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Lindsey Rojas",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Nicole C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Michelle Fry",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Peter A.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Charles Marsh",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Whitney H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Noah Spencer",
        "service_type": "Garage Door Repair",
        "tech_name": "Edwin C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Anthony Burns",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kendra F.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Scott Lawrence",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kathleen S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Joseph Fleming",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joshua A.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Joshua Wallace",
        "service_type": "Garage Door Track Repair",
        "tech_name": "William G.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Mike Collins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jennifer W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Angel Collins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Javier G.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Madison Mccann",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joshua J.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Gabriela Jensen",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Vernon N.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "John Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kristina B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Kelly Johnson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Donald R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Mrs. Lydia Flores DDS",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tracy W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Rebecca Mason",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Thomas B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Joseph Mcmahon",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Marissa J.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Jennifer Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sarah S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Rebecca Singh",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ricardo L.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-26",
        "review_rate": 5.0,
        "customer_name": "Alexis Washington",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer G.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Lee Taylor",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Christopher L.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Duane Robles",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Patricia B.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Sylvia Stafford",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "David J.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Angela Moore",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dawn T.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Wagner",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jackson D.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Anne Douglas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kelli P.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Lori May",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Karen S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Emily Cox",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Hayden P.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Amber Stone MD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Anne N.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Peggy Caldwell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brandi W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Megan Carlson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kim T.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "David Hudson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Daniel C.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Marie Phillips",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jennifer T.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Emily Davidson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Steven A.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Anne Cruz",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brandon S.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Patrick Clarke",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Amy D.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Michele Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Diana R.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Mr. James Smith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Carla S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Zachary Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Bryce J.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Jason Hardy",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "John Robinson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mary M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Katrina Thompson",
        "service_type": "Garage Door Services",
        "tech_name": "Gregory L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Albert Robles",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cynthia B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Monica Lang",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Robert J.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Richard Davis",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael G.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "James Luna",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Helen E.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Anthony Riley",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ronald F.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Susan Bowen",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Eric W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Erin Villegas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jessica F.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Erin Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Shane O.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.8,
        "customer_name": "Justin Porter",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joshua W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Michael Ramos",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Vincent J.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Emily Valdez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kelsey H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Chelsea Daugherty",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christina R.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "Ernest Sharp",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Chelsea R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Sara Reed",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joshua K.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Matthew Martin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Nicholas O.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Evan Carter",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michael O.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Tonya Washington",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kevin L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Edward Thompson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Krista C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Cynthia Marquez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Peter C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Leonard Erickson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rebecca P.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Brian Hess",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Stephanie B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Matthew Jenkins",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joshua R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Janice Welch",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kathleen L.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Gwendolyn Jones",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Chelsea M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Douglas Taylor",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mark G.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Virginia Jones MD",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David P.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Angela Mcgee",
        "service_type": "Garage Door Services",
        "tech_name": "Joshua B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Jennifer Ramos",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kelly G.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Sean Summers",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joel E.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Anne Davis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sara L.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Michael Morgan",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brandi F.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Stephanie Coleman",
        "service_type": "Garage Door Repair",
        "tech_name": "Zoe P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Richard Price",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Clayton M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "William Ramos",
        "service_type": "Garage Door Services",
        "tech_name": "Matthew C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Kayla Ortiz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jacqueline B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Alexandria Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Melody P.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Tom Perez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Rodney F.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Kenneth Allen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amber W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Brian Flores",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Eric N.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Bruce Wright",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christopher J.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Kenneth Smith",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Yesenia G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Cody Barnes",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Melanie J.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Katherine Gutierrez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lisa C.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Nicole Huff",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Pamela Z.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Mr. Maurice Patel Jr.",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alison R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "Christina Norton",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "John C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Nicole Simmons",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Patty M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Ashley Coleman",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brent D.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Ryan Henry",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dalton G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Susan Manning",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Teresa O.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Chelsey Bryant",
        "service_type": "Garage Door Installation",
        "tech_name": "Justin R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Donna Estrada",
        "service_type": "Garage Door Off Track",
        "tech_name": "Adam M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Jose Richardson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nancy C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Debra Potter",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jennifer C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.8,
        "customer_name": "Jeremiah Mccormick",
        "service_type": "Garage Door Installation",
        "tech_name": "Mary T.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Brandon Bell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "James Moore",
        "service_type": "Garage Door Services",
        "tech_name": "Amanda M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Kevin Martinez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sabrina M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Keith Davis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jay B.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-08",
        "review_rate": 5.0,
        "customer_name": "Christopher Palmer",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lisa F.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Stephanie Aguirre",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kristina C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Tiffany Wolfe",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jill S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Kyle Jacobs",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Misty M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Diamond Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sarah F.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "Natalie Estes",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Charles H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Aaron Ward",
        "service_type": "Garage Door Off Track",
        "tech_name": "David L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Lauren Fernandez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Rebecca C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Michael Williams",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Melanie V.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Keith Buckley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nathan L.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Ashley Hall",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Richard F.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Michelle Logan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Alexander S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Pamela Irwin",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Julie D.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Robert Shah",
        "service_type": "Garage Door Installation",
        "tech_name": "Jeremy H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Nicholas Riley",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Amy H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Jenna Aguilar",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Julie A.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Lisa Miller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Grace B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Patrick Barker",
        "service_type": "Garage Door Installation",
        "tech_name": "Pamela W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Ashlee Mills",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sherry W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Michelle Cervantes",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Candace L.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Aaron Anderson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michele W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Andrew Ross",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Erin C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Martin Jones",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Melissa J.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Samantha Hoffman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Matthew B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Kelly Shelton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "David B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Kathleen Sanchez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Stephanie B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Nicholas Chase",
        "service_type": "Garage Door Insulation",
        "tech_name": "William W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Laura Mclean",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "John Snyder",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Maria S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Daniel Hunter",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher F.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Benjamin Barry",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Greg S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Angelica Underwood",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher Y.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Hannah Velasquez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "John L.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.8,
        "customer_name": "Gary Byrd",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Theresa G.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Desiree Robinson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Adam H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Dale Simpson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brandi S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Deanna Williams",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mark W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Brittany Le",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jonathan B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Carol Waters",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joshua M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Erika Saunders",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joseph C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Brian White",
        "service_type": "Garage Door Off Track",
        "tech_name": "Lauren A.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-04",
        "review_rate": 5.0,
        "customer_name": "Ian Hall",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Paige H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Amy Butler",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mathew W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Sara Clark",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sarah S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Anthony Whitehead",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Meagan S.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Shannon Mcintosh",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Peter R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Matthew Jackson",
        "service_type": "Garage Door Services",
        "tech_name": "Jeffrey B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Phillip Jensen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Carrie R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "Isaac Butler",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Thomas M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Gabriela Martinez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jonathan D.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Joshua Duran",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Stacy D.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Jessica Allen",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Timothy C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Kathleen King",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Paul H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Michael Phillips",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephen S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Ashley Martin",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gerald C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "Susan Aguilar",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Gregory O.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Catherine Stephenson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joseph W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-08",
        "review_rate": 5.0,
        "customer_name": "Erica Burton",
        "service_type": "Garage Door Repair",
        "tech_name": "Eric R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Justin Mercado",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christopher F.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Cynthia Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Benjamin A.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Kevin Garner",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Lindsey W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Susan Allen",
        "service_type": "Garage Door Installation",
        "tech_name": "Christopher C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Glenn Sanchez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jenny H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Samuel Ferguson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Carla J.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Rachael Castillo",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Janet D.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.8,
        "customer_name": "Kristopher Rogers",
        "service_type": "Garage Door Repair",
        "tech_name": "Taylor M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Karen Branch",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Wesley S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-17",
        "review_rate": 5.0,
        "customer_name": "Travis Smith",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rodney T.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Jonathan Hunter",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Adam F.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Dr. Janet Compton",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Derek H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-05",
        "review_rate": 5.0,
        "customer_name": "Olivia Taylor",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Deborah B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Jared Nelson",
        "service_type": "Garage Door Repair",
        "tech_name": "Amanda C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Kelli Torres",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Claudia M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Ashley Turner",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Dana C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Karen Sullivan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Richard W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Nicole Jordan",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Amber P.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Jacob Martinez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kelsey P.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Aaron Fisher",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "James T.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "John Boyle",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jennifer R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Ronald Pineda",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Adam M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Oneill",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kayla M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Martin Price",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Emily F.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Christopher Zavala",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Terri N.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Susan Fisher",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Alyssa G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "James Edwards",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "James M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Christine Harris",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Angela C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Charlene Hunter",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christina B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Allen Patterson DDS",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jennifer S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Sonya Carroll",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Shannon H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "James Farmer",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brian M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Jake Lambert",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Craig B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Virginia Sanders",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Caleb M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Jessica Horn",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gregory H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Aaron Hurley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michelle B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Amanda Chang",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Shelly E.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Keith Church",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nicole Z.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Lauren Deleon",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Peggy C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Alyssa Lee",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sandra F.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Ms. Lindsey Cunningham",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nicholas H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Linda Carrillo",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Dawn N.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Angela Anderson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robert H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Thomas Baker",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Eric C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Linda James",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Angel S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Barbara Powell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christian H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Brian Peters",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "James F.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Michael Cooper",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "April T.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Jay Middleton",
        "service_type": "Garage Door Services",
        "tech_name": "Thomas F.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Laura Martin",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Donald W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Jeffery Park",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Dana W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Jennifer Singh",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Mitchell C.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Tammy Hoffman",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Whitney F.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Dominique Bender",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Steven W.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Eric Mccullough",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Cynthia G.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Nicole Henson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Vanessa S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Deborah Bailey",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "David R.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Ashley Knight",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Larry R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Maureen Miller",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jessica A.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Mary Hensley",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Manuel P.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Cory Pham",
        "service_type": "Garage Door Services",
        "tech_name": "Lacey H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Jason Rogers",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tom W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Clifford Kim",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "John G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Courtney Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Morgan Z.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Ruth Rodriguez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Benjamin N.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Abigail Fleming",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Shannon M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-16",
        "review_rate": 5.0,
        "customer_name": "Madeline Wright",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jon S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Jessica Pennington",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Scott B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Shannon Cunningham",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Gary A.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Mr. Randy Allen DDS",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kimberly P.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Yolanda Harmon",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kristopher G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Alison Miller",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alejandro G.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Kathy Blanchard",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ricky S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "Preston Rhodes",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James L.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-11",
        "review_rate": 5.0,
        "customer_name": "Anthony Pennington",
        "service_type": "Garage Door Repair",
        "tech_name": "Robert R.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Traci Mcmahon",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Taylor K.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Jessica Dean",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Angela S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Mrs. Cynthia Vaughn MD",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Juan V.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Laurie Ewing",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Matthew R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Jose Jackson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tracy W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Diana Weeks",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Karen S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Amber Garcia",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michelle B.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Brett Butler",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Joshua J.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "James Collins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kimberly H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Janet Rogers",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Chris W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Michael Oneal",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ryan Y.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Dawn Holmes",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brittany K.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Julia Reid",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Elizabeth B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "John Sellers",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Angela H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Holly Wagner",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Karen O.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Jamie Mora",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brenda R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "James Pham",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Robert J.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Alicia Sullivan",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joanna D.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Mr. Thomas White",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jessica C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "John Obrien",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Elizabeth P.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "James Macdonald",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Charles A.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Christopher Robbins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brittany O.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.8,
        "customer_name": "Daniel Lynch",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Alicia B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Danny Stone",
        "service_type": "Garage Door Opener",
        "tech_name": "Roberto P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Sally White",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Holly M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Kaitlyn Jones",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Laura M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Gordon Wright",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amy T.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Susan Turner",
        "service_type": "Garage Door Services",
        "tech_name": "Javier H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Keith Wallace",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robert T.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Jacob Meyer",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Marie S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Tammy Scott",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jacob B.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Amanda Swanson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Scott C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Amanda Weber",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Adrian J.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Sara Love",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Teresa P.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Kim Cobb",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kaitlin N.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Deborah Davis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Aaron M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Sharon Obrien",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Keith R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Anthony French",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Alexander M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "James Rivera",
        "service_type": "Garage Door Installation",
        "tech_name": "Nicole A.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Lori Keller",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Dakota B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Lori Brewer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kevin G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Zachary Chavez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ronald L.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Jessica Hall",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kathleen C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Jennifer Clay",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lauren F.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Sarah Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Howard V.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Tracy Mora",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Patrick T.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Nicholas Stewart",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kyle F.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Gail Thompson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Natalie R.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Robert Perez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Matthew R.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-27",
        "review_rate": 5.0,
        "customer_name": "Alexander Torres",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Regina H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Shannon Lee",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mary L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Lisa Sanchez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Veronica T.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Nathan Johnston",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sue D.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Jennifer Turner",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Thomas R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Jessica Williams",
        "service_type": "Garage Door Off Track",
        "tech_name": "Cassandra L.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Heather Flores",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Scott B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Brianna Miller",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael D.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Colleen Murray",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Zachary K.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Mary Martin",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "William V.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Sean Vargas",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Craig G.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Jessica Stone",
        "service_type": "Garage Door Repair",
        "tech_name": "Jared L.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Gregory Rowe",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Mark Jones",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brian S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Tyler Hale",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stacy H.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Robert Hall",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Carlos M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Leslie Alexander",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joseph H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Brandon Grant",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Amber D.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Mark Bryant",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Adrienne M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Gabriel Thomas",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Phillip R.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Justin Tucker",
        "service_type": "Garage Door Repair",
        "tech_name": "Larry G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Calvin Ortiz",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Anthony M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Nicholas Gomez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mario R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "John Dixon",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Caitlin R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Jennifer Silva",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nancy D.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Melissa Montgomery",
        "service_type": "Garage Door Repair",
        "tech_name": "Philip S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Ashley Smith",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michael R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "David Day",
        "service_type": "Garage Door Off Track",
        "tech_name": "Patrick T.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Charles Williams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Leslie M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Paul Allen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kenneth E.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Kenneth Goodman",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sara N.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Brittney Reyes",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Karen G.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Samuel Morgan",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Robert O.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Ralph Valdez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Bryan C.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Seth Ellison",
        "service_type": "Garage Door Services",
        "tech_name": "Andrea S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Joseph Adams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Emily M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Mark Nelson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kellie N.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Shelley Robinson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "David T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Andrea Lee",
        "service_type": "Garage Door Insulation",
        "tech_name": "Antonio B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Lawrence Glover",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kathleen A.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Matthew Carrillo",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-16",
        "review_rate": 5.0,
        "customer_name": "Christopher Brooks",
        "service_type": "Garage Door Repair",
        "tech_name": "Christopher T.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Ian Salazar",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Amy W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Rachael Soto",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gina P.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Amy Davis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kristy W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Nathaniel Mitchell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Chelsea R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Jasmine Pratt",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "James J.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Jordan Knight",
        "service_type": "Garage Door Installation",
        "tech_name": "Lisa P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.8,
        "customer_name": "Nicole Thomas",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Timothy O.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Jennifer Page",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Whitney Lindsey",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Erik S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Raymond Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Jared F.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Cassandra Reid",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jacqueline J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Vickie Thomas",
        "service_type": "Garage Door Installation",
        "tech_name": "Thomas W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Scott King",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amy R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Zachary Rivas",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Hannah C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "James Bender",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jane F.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
