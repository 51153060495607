import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import './SiteMap.scss';

// import { useLocation } from "react-router-dom";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useEffect } from 'react';
import AreaData from '../../Components/ServicesPage/AreaData'
import LocationData from '../../Components/LocationAll/LocationData'


export default function SiteMap({ Blogs, FaqList, WebSitePages }) {
    const location = useLocation()
    const [SiteMapToArray, setSiteMapToArray] = useState([]);


    useEffect(() => {
        if (location.pathname.split('/')[2] == "website" || location.pathname.split('/')[1] == "sitemaps" || location.pathname.split('/').length <= 2 && location.pathname.split('/')[1] == "sitemap") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "solutions") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "blog") {
            setSiteMapToArray(Blogs)
        } else if (location.pathname.split('/')[2] == "faq") {
            setSiteMapToArray(FaqList)
        } else if (location.pathname.split('/')[2] == "socialmedia") {
            setSiteMapToArray(WebSitePages)
        }
    }, [location.pathname]);

    return (
        <div className="SiteMap">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Long Beach Garage Door | Site Map</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Long Beach Garage Door | Site Map" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Long Beach Garage Door | Site Map" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address --> */}
                <meta name="og:url" property="og:url" content="https://www.garagedoorlongbeach.org/sitemap" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the comprehensive sitemap for Long Beach Garage Door. Find detailed links to our garage door installation, repair, inspection, and maintenance services to ensure your home’s security and functionality." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the comprehensive sitemap for Long Beach Garage Door. Find detailed links to our garage door installation, repair, inspection, and maintenance services to ensure your home’s security and functionality." data-react-helmet="true" />
                <meta name="keywords" content="Garage Door, Garage Door Installation, Garage Door Inspection, Garage Door Repair, Garage Door Maintenance, Garage Door Opener Installation, Garage Door Off Track Repair, Garage Door Spring Repair, Garage Door Remote Programming" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Long Beach Garage Door | Site Map" data-react-helmet="true" />
            </Helmet>
            <div className="SiteMapMenu">
                <a href="/">Website</a>
            </div>
            <div className="SiteMapDes">
                <div className='SiteMapDesBox'>
                    <a href="/">Home Page</a>
                    <a href="/contact">Contact Us</a>
                    <a href="/book">Book Garage Door Appointment Online In Long Beach, California</a>
                    <a href={`/reviews/${LocationData.find(a => a.City == ("Long Beach").replace("%20", '')).City.replace(" ", '').toLowerCase()}`}>Reviews</a>
                    <a href="/services">Our Services</a>
                    <a href="/locations">Our Locations</a>
                    <a href="/blog">Blog</a>
                    <a href="/faq">Faq</a>
                    <a href="/sitemap">Site Map</a>

                    {/* Service */}
                    <h1>Services</h1>

                    {AreaData.map((A, index) =>
                        <a href={A.Path} className="Header1BoxMenuBox">
                            {A.Title}
                        </a>
                    )}

                    {/* Reviews */}
                    <h1>Locations</h1>
                    {LocationData.map((A, index) =>
                        <a href={`/reviews/${A.City.replace(' ', '').toLowerCase()}`} >{A.City} Garage Door</a>
                    )}

                    <h1>Blog</h1>
                    {/* Blog */}
                    {Blogs.map((A, index) =>
                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox">{A.Title}</a>
                    )}
                    {/* Faq */}

                    <h1>Faqs</h1>
                    {FaqList.map((A, index) =>
                        <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`}>{A.question}</a>

                    )}

                </div>



            </div>
            <NavigatorPath />
        </div>
    )
}
